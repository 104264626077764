.ant-table-wrapper {
  width: 100%;
  overflow-x: auto;
}

.ant-table {
  width: 100%;
}

/* Para que la tabla ocupe el ancho completo */
.card-body {
  padding: 0.5rem !important;
}

/* Botón de asistencia */
.assist-button.bg-primary {
  background-color: #52c41a !important;
  border-color: #52c41a !important;
  color: white !important;
}

.assist-button.bg-secondary {
  background-color: #ff4d4f !important;
  border-color: #ff4d4f !important;
  color: white !important;
}

/* Contenedor de acciones */
.table-actions-container {
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: flex-start;
}

/* Estilos específicos para móvil */
@media (max-width: 767px) {
  .ant-table .ant-table-cell {
    padding: 8px 4px;
    font-size: 13px;
    white-space: nowrap;
  }

  /* Dar más espacio al nombre */
  .ant-table .ant-table-cell:nth-child(3) {
    min-width: 120px;
  }

  /* Hacer los botones más grandes para facilitar el toque */
  .btn-sm {
    min-width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* Mostrar totales de plazas uno debajo del otro */
  .lesson-stats {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
  }

  .lesson-stats>div {
    margin-bottom: 4px;
  }
}