.footer {
  margin-top: 30px;
  text-align: center;
}

.footer span {
  display: block;
  margin-bottom: 15px;
  color: white;
  font-size: 18px;
}

.logo-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 20px 0;
}

.logo-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100px;
  padding: 5px;
  background-color: white;
  /* Fondo blanco para destacar los logos */
  border-radius: 8px;
  /* Bordes redondeados */
}

.logo-item img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  /* Esto preserva las proporciones de la imagen */
  transition: transform 0.2s;
}

.logo-item img:hover {
  transform: scale(1.1);
}

/* Estilo específico para el logo de Apple */
.logo-item.apple img {
  max-height: 80%;
  /* Asegura que el logo de Apple no se vea aplastado */
}

/* Enlaces en el footer */
.footer-links a {
  color: white;
  text-decoration: none;
  margin: 0 10px;
  font-size: 12px;
  transition: opacity 0.2s;
}

.footer-links a:hover {
  opacity: 0.8;
  text-decoration: underline;
}