.parent {
    overflow: hidden;
    /* required */



}

.muerdete-banner {
    margin: 0;
    padding: 0;
    background: #dc2626;
    color: white;
    padding: 1em 0;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    transform: translateX(30%) translateY(0%) rotate(45deg);
    transform-origin: top left;
}

.muerdete-banner:before,
.muerdete-banner:after {
    content: '';
    position: absolute;
    top: 0;
    margin: 0 -1px;
    /* tweak */
    width: 25%;
    height: 100%;
    background: #dc2626;
}

.muerdete-banner:before {
    right: 100%;
}

.muerdete-banner:after {
    left: 100%;
}